<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-overlay :show="loading">
              <b-row>
                <b-col v-if="isDataFound" lg="12" sm="12">
                  <p style="font-weight:bold; color:gray; text-transform:uppercase">{{ $t('pump_install.participation_fee') }}</p>
                  <hr style="margin-top:-15px">
                  <b-table-simple striped bordered small style="width:100%">
                      <b-tr>
                        <b-th style="width:30%" class="text-right pr-3">{{$t('pump_install.CircleArea')}}</b-th>
                        <b-td style="width:5%" class="text-center">:</b-td>
                        <b-td>{{ participationFee[0].circle }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-th class="text-right pr-3">{{$t('pump_install.amount')}}</b-th>
                        <b-td class="text-center">:</b-td>
                        <b-td>{{ participationFee[0].amount }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-th class="text-right pr-3">{{$t('globalTrans.type')}}</b-th>
                        <b-td class="text-center">:</b-td>
                        <b-td>
                          <span class="badge badge-primary" v-if="participationFee[0].payment_type === 1">{{$t('pump_install.Advanced') }}</span>
                          <span class="badge badge-danger" v-if="participationFee[0].payment_type === 2">{{$t('pump_install.Due') }}</span>
                        </b-td>
                      </b-tr>
                      <b-tr>
                        <b-th class="text-right pr-3">{{$t('globalTrans.status')}}</b-th>
                        <b-td class="text-center">:</b-td>
                        <b-td>{{ getStatus(participationFee[0].payment_status) }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-th class="text-right pr-3">{{$t('pump_install.payment_date')}}</b-th>
                        <b-td class="text-center">:</b-td>
                        <b-td>{{ participationFee[0].payment_date }}</b-td>
                      </b-tr>
                  </b-table-simple>
                </b-col>
                <b-col v-else lg="12" sm="12">
                  <p style="font-weight:bold; color:gray; text-align:center text-transform:uppercase">{{ $t('pump_install.data_not_found') }}</p>
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '../../../../../config/pluginInit'
import RestApi, { irriSchemeServiceBaseUrl } from '../../../../../config/api_config'
import { getParticipationFee } from '../../api/routes'

export default {
  props: ['id', 'org_id'],
  mounted () {
    core.index()
  },
  created () {
    this.loadData()
  },
  data () {
    return {
      loading: false,
      isDataFound: false,
      participationCategoryList: [
        { value: 1, text: 'Deep Tubewell' },
        { value: 2, text: 'Irrigation Drain' },
        { value: 3, text: 'Electrification' },
        { value: 4, text: 'Solar' }
      ],
      participationFee: [
        {
          circle: '',
          amount: 0
        }
      ],
      securityMoney: ''
    }
  },
  computed: {
    circleAreaList: function () {
      return this.$store.state.IrriConfig.commonObj.circleArea.filter(item => item.status === 0)
    },
    pumpTypeList: function () {
      return this.$store.state.IrriConfig.commonObj.pumpType.filter(item => item.status === 0)
    }
  },
  methods: {
    async loadData () {
      this.loading = true
      let result = null
      result = await RestApi.getData(irriSchemeServiceBaseUrl, getParticipationFee + '/' + this.$props.id + '/' + this.$props.org_id)
      this.loading = false
      if (result.success) {
        this.isDataFound = true
        if (result.data.participationFee.length > 0) {
          this.participationFee = result.data.participationFee
          const circleArea = this.circleAreaList.find(circle => circle.value === this.participationFee[0].circle_area_id)
          this.participationFee[0].circle = circleArea.text
        }
      } else {
        this.isDataFound = false
      }
    },
    getParticipationCategory (categoryId) {
        if (categoryId === 1) {
            return 'Deep Tubewell'
        } else if (categoryId === 2) {
            return 'Irrigation Drain'
        } else if (categoryId === 3) {
            return 'Electrification'
        } else {
            return 'Solar'
        }
    },
    getStatus (paymentStatus) {
        if (paymentStatus === 1) {
            return 'Unpaid'
        } else {
            return 'Paid'
        }
    },
    getPumpType (pumpTypeId) {
        const pumpTypeList = this.$store.state.IrriConfig.commonObj.pumpType.filter(item => item.status === 0)
        const tmpPump = pumpTypeList.find(pumpType => pumpType.value === pumpTypeId)
        return this.$i18n.locale === 'bn' ? tmpPump.text_bn : tmpPump.text_en
    }
  }
}
</script>
