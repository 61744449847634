<template>
  <b-container fluid>
    <iq-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('pump_install.participation_fee_application') }}</h4>
      </template>
      <template v-slot:body>
        <b-row>
          <b-col lg="4" md="6" sm="12" xs="12">
            <b-form-group
              class="row"
              label-cols-sm="4"
              :label="$t('pump_install.application_id')"
              label-for="application_id"
            >
              <b-form-input
                id="application_id"
                v-model="search.application_id"
                placeholder=""
                ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col lg="4" md="6" sm="12" xs="12">
            <b-form-group
              class="row"
              label-cols-sm="4"
              :label="$t('pump_install.name')"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model="search.name"
                placeholder=""
                ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col lg="4" md="6" sm="12" xs="12">
            <b-form-group
              class="row"
              label-cols-sm="4"
              :label="$t('irrigation_config.scheme_typel')"
              label-for="scheme_type_id"
            >
              <b-form-select
                plain
                id="scheme_type_id"
                v-model="search.scheme_type_id"
                :options="SchemeTypeList"
                >
                  <template v-slot:first>
                    <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                </b-form-select>
            </b-form-group>
          </b-col>

          <b-col lg="4" md="6" sm="12" xs="12">
            <b-form-group
              class="row"
              label-cols-sm="4"
              :label="$t('pump_install.organization')"
              label-for="org_id"
            >
              <b-form-select
                plain
                id="org_id"
                v-model="search.org_id"
                :options="orgList"
                >
                  <template v-slot:first>
                    <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                </b-form-select>
            </b-form-group>
          </b-col>
          <b-col lg="4" md="6" sm="12" xs="12">
            <b-form-group
              class="row"
              label-cols-sm="4"
              :label="$t('pump_install.division')"
              label-for="far_division_id"
            >
              <b-form-select
                plain
                id="far_division_id"
                v-model="search.far_division_id"
                :options="divisionList"
                >
                  <template v-slot:first>
                    <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                </b-form-select>
            </b-form-group>
          </b-col>
          <b-col lg="4" md="6" sm="12" xs="12">
            <b-form-group
              class="row"
              label-cols-sm="4"
              :label="$t('pump_install.district')"
              label-for="far_district_id"
            >
              <b-form-select
                plain
                id="far_district_id"
                v-model="search.far_district_id"
                :options="districtList"
                >
                  <template v-slot:first>
                    <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                </b-form-select>
            </b-form-group>
          </b-col>

          <b-col lg="4" md="6" sm="12" xs="12">
            <b-form-group
              class="row"
              label-cols-sm="4"
              :label="$t('pump_install.upazila')"
              label-for="far_upazilla_id"
            >
              <b-form-select
                plain
                id="far_upazilla_id"
                v-model="search.far_upazilla_id"
                :options="upazilaList"
                >
                  <template v-slot:first>
                    <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                </b-form-select>
            </b-form-group>
          </b-col>
          <b-col lg="4" md="6" sm="12" xs="12">
            <b-form-group
              class="row"
              label-cols-sm="4"
              :label="$t('pump_install.union')"
              label-for="far_union_id"
            >
              <b-form-select
                plain
                id="far_union_id"
                v-model="search.far_union_id"
                :options="unionList"
                >
                  <template v-slot:first>
                    <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                </b-form-select>
            </b-form-group>
          </b-col>
          <b-col lg="4" md="6" sm="12" xs="12">
            <b-form-group
              class="row"
              label-cols-sm="4"
              :label="$t('pump_install.mobile')"
              label-for="far_mobile_no"
            >
              <b-form-input
                plain
                id="far_mobile_no"
                v-model="search.far_mobile_no"
                >
                </b-form-input>
            </b-form-group>
          </b-col>

          <b-col lg="4" md="6" sm="12" xs="12">
            <b-form-group
              class="row"
              label-cols-sm="4"
              :label="$t('pump_install.father_name')"
              label-for="father_name"
            >
              <b-form-input
                plain
                id="father_name"
                v-model="search.father_name"
                >
                </b-form-input>
            </b-form-group>
          </b-col>
          <b-col lg="4" md="6" sm="12" xs="12">
            <b-form-group
              class="row"
              label-cols-sm="4"
              :label="$t('pump_install.mother_name')"
              label-for="mother_name"
            >
              <b-form-input
                plain
                id="mother_name"
                v-model="search.mother_name"
                >
                </b-form-input>
            </b-form-group>
          </b-col>
          <b-col lg="4" md="6" sm="12" xs="12">
            <b-form-group
              class="row"
              label-cols-sm="4"
              :label="$t('pump_install.nid')"
              label-for="nid"
            >
              <b-form-input
                plain
                id="nid"
                v-model="search.nid"
                >
                </b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="12" class="text-right">
            <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
          </b-col>
        </b-row>
      </template>
    </iq-card>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('pump_install.participation_fee_application') }}</h4>
          </template>
          <template v-slot:body>
            <b-overlay :show="loadingState">
              <b-row>
                <b-col md="12" class="table-responsive">
                  <b-table thead-class="table_head" bordered hover :items="applications" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                    <template v-slot:cell(index)="data">
                      {{ $n(data.index + pagination.slOffset) }}
                    </template>
                    <template v-slot:cell(application_id)="data">
                      {{ $n(data.item.application_id, {useGrouping: false}) }}
                    </template>
                    <template v-slot:cell(scheme_location)="data">
                      {{ getSchemeLocation(data.item) }}
                    </template>
                    <template v-slot:cell(view_application)="data">
                      <a href="javascript:" class="btn_table_action table_action_edit" v-b-modal.modal-detail title="View" @click="setRowId(data.item, 1, $t('pump_install.application_details'))"><i class="far fa-eye"></i></a>
                    </template>
                    <template v-slot:cell(created_date)="data">
                      <span :title="data.item.created_at | dateFormat">{{ data.item.created_at | dateFormat}}</span>
                    </template>
                    <template v-slot:cell(attached_document_link)="data">
                      <a v-if="data.item.general_minutes !== null" :href="baseUrl + 'download-attachment?file=uploads/scheme-application/general-minutes/original/' + data.item.general_minutes" title="General Minute" class="mr-3"><i class="ri-download-cloud-fill"></i></a>
                      <a v-if="data.item.scheme_lands !== null" :href="baseUrl + 'download-attachment?file=uploads/scheme-application/scheme-lands/original/' + data.item.scheme_lands" title="Scheme Land" class="mr-3"><i class="ri-download-cloud-fill"></i></a>
                      <a v-if="data.item.scheme_map !== null" :href="baseUrl + 'download-attachment?file=uploads/scheme-application/scheme-map/original/' + data.item.scheme_map" title="Scheme Map"><i class="ri-download-cloud-fill"></i></a>
                    </template>
                     <template v-slot:cell(action)="data">
                      <a href="javascript:" class="btn_table_action table_action_edit" v-b-modal.modal-1 :title="$t('pump_install.select_fee')" @click="setRowId(data.item, 2, $t('pump_install.select_fee'))"><i class="fas fa-wallet"></i></a>
                      <a href="javascript:" class="btn_table_action table_action_view" v-b-modal.modal-1 :title="$t('pump_install.fee_details')" @click="setRowId(data.item, 3, $t('pump_install.fee_details'))"><i class="fas fa-eye"></i></a>
                    </template>
                  </b-table>
                  <b-pagination
                    align="center"
                    v-model="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalRows"
                    @input="paginationDataLoad"
                  />
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-modal id="modal-detail" size="xl" :title="modalTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
        <ApplicationDetails :id="id" :key="id" v-if="modalType === 1"/>
    </b-modal>
    <b-modal id="modal-1" size="xl" :title="modalTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <p>
        <slot v-if="org_id === 15">
          <AddFeeBmda :id="id" :org_id="org_id" :application_type_id="application_type_id" :key="id" v-if="modalType === 2"/>
          <FeeDetailsBmda :id="id" :org_id="org_id" :key="id" v-if="modalType === 3"/>
        </slot>
        <slot v-else>
          <AddFee :id="id" :org_id="org_id" :application_type_id="application_type_id" :key="id" v-if="modalType === 2"/>
          <FeeDetails :id="id" :org_id="org_id" :key="id" v-if="modalType === 3"/>
        </slot>
      </p>
    </b-modal>
  </b-container>
</template>
<script>
import { core } from '../../../../../config/pluginInit'
import ApplicationDetails from '../SchemeApplicationDetails'
import RestApi, { irriSchemeServiceBaseUrl } from '@/config/api_config'
import { participationFeeApplication } from '../../api/routes'
import AddFee from './AddFee'
import FeeDetails from './FeeDetails'
import AddFeeBmda from './AddFeeBmda'
import FeeDetailsBmda from './FeeDetailsBmda'
import { mapGetters } from 'vuex'

export default {
  name: 'UiDataTable',
  components: {
    ApplicationDetails,
    AddFee,
    FeeDetails,
    AddFeeBmda,
    FeeDetailsBmda
  },
  data () {
    return {
      modalType: -111,
      modalTitle: '',
      org_id: '',
      applications: [],
      pagination: {
        currentPage: 1,
        totalRows: 0,
        perPage: this.$store.state.commonObj.perPage,
        slOffset: 1
      },
      baseUrl: irriSchemeServiceBaseUrl,
      search: {
        application_id: '',
        name: '',
        scheme_type_id: 0,
        org_id: 0,
        far_division_id: 0,
        far_district_id: 0,
        far_upazilla_id: 0,
        far_union_id: 0,
        far_mobile_no: '',
        father_name: '',
        mother_name: '',
        nid: ''
      },
      id: 0,
      rows: [],
      districtList: [],
      upazilaList: [],
      unionList: [],
      status: '',
      location: ''
    }
  },
  computed: {
    ...mapGetters({
      authUser: 'Auth/authUser'
    }),
    loading () {
      return this.$store.state.commonObj.loading
    },
    listReload () {
      return this.$store.state.commonObj.listReload
    },
    loadingState () {
      if (this.listReload) {
        return true
      } else if (this.loading) {
        return true
      } else {
        return false
      }
    },
    columns () {
      const labels = [
        { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
        { label: this.$t('pump_install.application_id'), class: 'text-center' },
        { label: this.$t('pump_install.applicant_name'), class: 'text-center' },
        { label: this.$t('pump_install.scheme_location'), class: 'text-center' },
        { label: this.$t('pump_install.license_no'), class: 'text-center' },
        { label: this.$t('pump_install.view_application'), class: 'text-center' },
        { label: this.$t('pump_install.attached_document_link'), class: 'text-center' },
        { label: this.$t('pump_install.created_date'), class: 'text-center' },
        { label: this.$t('globalTrans.action'), class: 'text-center w-10' }
      ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'application_id' },
          { key: 'name_bn' },
          { key: 'scheme_location' },
          { key: 'license_no' },
          { key: 'view_application' },
          { key: 'attached_document_link' },
          { key: 'created_date' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'application_id' },
          { key: 'name' },
          { key: 'scheme_location' },
          { key: 'license_no' },
          { key: 'view_application' },
          { key: 'attached_document_link' },
          { key: 'created_date' },
          { key: 'action' }
        ]
      }

      return labels.map((item, index) => {
        return Object.assign(item, keys[index])
      })
    },
    orgList: function () {
      return this.$store.state.commonObj.organizationProfileList
    },
    divisionList: function () {
      return this.$store.state.commonObj.divisionList.filter(item => item.status === 0)
    },
    SchemeTypeList: function () {
      return this.$store.state.IrriConfig.commonObj.schemeTypeList.filter(item => item.status === 0)
    }
  },
  watch: {
    loadingState: function (newVal, oldVal) {
      if (newVal) {
        this.loadData()
      }
    },
    'search.far_division_id': function (newVal, oldVal) {
      this.districtList = this.getDistrictList(newVal)
    },
    'search.far_district_id': function (newVal, oldVal) {
      this.upazilaList = this.getUpazilaList(newVal)
    },
    'search.far_upazilla_id': function (newVal, oldVal) {
      this.unionList = this.getUnionList(newVal)
    }
  },
  mounted () {
    core.index()
    if (this.authUser.role_id === 0 && this.authUser.is_org_admin === 1) {
      this.search = Object.assign({}, this.search, {
        org_id: this.authUser.org_id
      })
    } else if (this.authUser.role_id === 0) {
      this.search = Object.assign({}, this.search, {
        org_id: this.authUser.office_detail.org_id,
        far_division_id: this.authUser.office_detail.division_id,
        far_district_id: this.authUser.office_detail.district_id,
        far_upazilla_id: this.authUser.office_detail.upazilla_id
      })
    }
    this.loadData()
  },
  methods: {
    resetId () {
      this.id = 0
    },
    paginationDataLoad () {
      this.loadData()
    },
    paginationData (data) {
      this.pagination.currentPage = data.current_page
      this.pagination.totalRows = data.total
      this.pagination.slOffset = this.pagination.perPage * this.pagination.currentPage - this.pagination.perPage + 1
    },
    searchData () {
      if (this.authUser.role_id === 0 && this.authUser.is_org_admin === 1) {
        this.search = Object.assign({}, this.search, {
          org_id: this.authUser.org_id
        })
      } else if (this.authUser.role_id === 0) {
        this.search = Object.assign({}, this.search, {
          org_id: this.authUser.office_detail.org_id,
          far_division_id: this.authUser.office_detail.division_id,
          far_district_id: this.authUser.office_detail.district_id,
          far_upazilla_id: this.authUser.office_detail.upazilla_id
        })
      }
      this.loadData()
    },
    setRowId (item, modalType, modalTitle) {
      this.id = item.id
      this.org_id = item.org_id
      this.application_type_id = item.application_type_id
      this.modalType = modalType
      this.modalTitle = modalTitle
    },
    changeLicenseStatus (item, isLicense) {
      this.$swal({
        title: isLicense === 0 ? this.$t('pump_install.have_license') : this.$t('pump_install.new_license'),
        showCancelButton: true,
        confirmButtonText: this.$t('globalTrans.yes'),
        cancelButtonText: this.$t('globalTrans.no'),
        focusConfirm: false
      }).then((result) => {
        if (result.isConfirmed) {
          this.license(item, isLicense)
        }
      })
    },
    async loadData () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      await RestApi.getData(irriSchemeServiceBaseUrl, participationFeeApplication, params).then(response => {
        if (response.success) {
          this.applications = response.data.data
          this.paginationData(response.data)
        }
      })
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    getDistrictList (divisionId = null) {
      const districtList = this.$store.state.commonObj.districtList.filter(item => item.status === 0)
      if (divisionId) {
        return districtList.filter(district => district.division_id === divisionId)
      }
      return districtList
    },
    getUpazilaList (districtId = null) {
      const upazilaList = this.$store.state.commonObj.upazilaList.filter(item => item.status === 0)
      if (districtId) {
        return upazilaList.filter(upazila => upazila.district_id === districtId)
      }
      return upazilaList
    },
    getUnionList (upazilaId = null) {
      const unionList = this.$store.state.commonObj.unionList.filter(item => item.status === 0)
      if (upazilaId) {
        return unionList.filter(union => union.upazilla_id === upazilaId)
      }
      return unionList
    },
    getSchemeLocation (item) {
      const address = []
      const unionList = this.$store.state.commonObj.unionList
      const tmpUnion = unionList.find(union => union.value === item.pump_union_id)
      if (this.$i18n.locale === 'bn') {
        address.push(item.pump_mauza_no_bn, item.pump_jl_no_bn, item.pump_plot_no_bn, tmpUnion.text_bn)
      } else {
        address.push(item.pump_mauza_no, item.pump_jl_no, item.pump_plot_no, tmpUnion.text_en)
      }
      const upazilaList = this.$store.state.commonObj.upazilaList
      const tmpUpazila = upazilaList.find(upazila => upazila.value === item.pump_upazilla_id)
      if (this.$i18n.locale === 'bn') {
        address.push(tmpUpazila !== undefined ? tmpUpazila.text_bn : '')
      } else {
        address.push(tmpUpazila !== undefined ? tmpUpazila.text_en : '')
      }
      const districtList = this.$store.state.commonObj.districtList
      const tmpDistrict = districtList.find(district => district.value === item.pump_district_id)
      if (this.$i18n.locale === 'bn') {
        address.push(tmpDistrict !== undefined ? tmpDistrict.text_bn : '')
      } else {
        address.push(tmpDistrict !== undefined ? tmpDistrict.text_en : '')
      }
      const divisionList = this.$store.state.commonObj.divisionList
      const tmpDivision = divisionList.find(division => division.value === item.pump_division_id)
      if (this.$i18n.locale === 'bn') {
        address.push(tmpDivision !== undefined ? tmpDivision.text_bn : '')
      } else {
        address.push(tmpDivision !== undefined ? tmpDivision.text_en : '')
      }
      return address.join(', ')
    }
  }
}
</script>
