<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-overlay :show="loading">
              <b-row>
                <b-col lg="12" sm="12">
                  <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
                    <b-form  @submit.prevent="handleSubmit(register)" @reset.prevent="reset" >
                      <p style="font-weight:bold; color:gray; text-transform:uppercase">{{ $t('pump_install.participation_fee') }}</p>
                      <hr style="margin-top:-15px">
                      <b-row>
                          <b-col lg="4">
                              <ValidationProvider name="Participation Category" vid="circle_area_id" rules="required">
                                  <b-form-group
                                      class="row"
                                      label-cols-sm="12"
                                      label-for="circle_area_id"
                                      slot-scope="{ valid, errors }"
                                  >
                                      <template v-slot:label>
                                          {{$t('pump_install.CircleArea')}} <span class="text-danger">*</span>
                                      </template>
                                      <b-form-select
                                          plain
                                          id="circle_area_id"
                                          v-model="participation.circle_area_id"
                                          :options="circleAreaList"
                                          :readonly="participation.payment_status === 2"
                                          @change="participationChange()"
                                      >
                                          <template v-slot:first>
                                              <b-form-select-option :value='null'>{{$t('globalTrans.select')}}</b-form-select-option>
                                          </template>
                                      </b-form-select>
                                      <div class="invalid-feedback">
                                          {{ errors[0] }}
                                      </div>
                                  </b-form-group>
                              </ValidationProvider>
                          </b-col>
                          <b-col lg="4">
                              <ValidationProvider name="Amount" vid="amount" rules="required|numeric">
                                  <b-form-group
                                      class="row"
                                      label-cols-sm="12"
                                      label-for="amount"
                                      slot-scope="{ valid, errors }"
                                  >
                                  <template v-slot:label>
                                      {{$t('pump_install.amount')}} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-input
                                      id="amount"
                                      v-model="participation.amount"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                      :readonly="participation.payment_status === 2"
                                  >
                                  </b-form-input>
                                  <div class="invalid-feedback">
                                      {{ errors[0] }}
                                  </div>
                                  </b-form-group>
                              </ValidationProvider>
                          </b-col>
                          <b-col lg="4">
                              <ValidationProvider name="payment_type" vid="payment_type" rules="required|numeric">
                                  <b-form-group
                                      class="row"
                                      label-cols-sm="12"
                                      label-for="payment_type"
                                      slot-scope="{ valid, errors }"
                                  >
                                  <template v-slot:label>
                                      {{$t('globalTrans.type')}} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-select
                                      id="payment_type"
                                      v-model="participation.payment_type"
                                      :options="participationTypes"
                                      :readonly="participation.payment_status === 2"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                      <template v-slot:first>
                                          <b-form-select-option :value='null'>{{$t('globalTrans.select')}}</b-form-select-option>
                                      </template>
                                  </b-form-select>
                                  </b-form-group>
                              </ValidationProvider>
                          </b-col>
                      </b-row>
                      <div class="row">
                          <div class="col-sm-3"></div>
                          <div class="col text-right">
                              <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                              &nbsp;
                              <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-1')">{{ $t('globalTrans.cancel') }}</b-button>
                          </div>
                      </div>
                    </b-form>
                  </ValidationObserver>
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '../../../../../config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { irriSchemeServiceBaseUrl } from '../../../../../config/api_config'
import { participationFeeStore, getMasterPaymentList } from '../../api/routes'

export default {
  props: ['id', 'org_id', 'application_type_id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    this.getMasterPaymentList()
  },
  mounted () {
    core.index()
  },
  data () {
    return {
        saveBtnName: this.$t('globalTrans.save'),
        loading: false,
        isEdit: false,
        participationCategoryList: [
          { value: 1, text: 'Deep Tubewell' },
          { value: 2, text: 'Irrigation Drain' },
          { value: 3, text: 'Electrification' },
          { value: 4, text: 'Solar' }
        ],
        participation: {
          scheme_application_id: this.$props.id,
          org_id: this.$props.org_id,
          circle_area_id: null,
          payment_type: null,
          amount: ''
        },
        partiCusecList: [],
        datas: [],
        circleData: this.$store.state.IrriConfig.commonObj.circleArea,
        secCusecList: []
    }
  },
  computed: {
    circleAreaList: function () {
      return this.$store.state.IrriConfig.commonObj.circleArea.filter(item => item.status === 0).map((obj, index) => {
            if (this.$i18n.locale === 'bn') {
            return { value: obj.value, text: obj.text_bn }
            } else {
            return { value: obj.value, text: obj.text_en }
            }
        })
    },
    participationTypes: function () {
      const participationType = [
        { value: 1, text: this.$t('pump_install.Advanced') },
        { value: 2, text: this.$t('pump_install.Due') }
      ]
      return participationType
    },
    pumpTypeList: function () {
      return this.$store.state.IrriConfig.commonObj.pumpType.filter(item => item.status === 0)
    }
  },
  methods: {
    participationChange () {
      if (this.participation.circle_area_id) {
        const circleArea = this.circleData.find(circle => circle.value === this.participation.circle_area_id)
        this.participation.amount = circleArea.amount
      }
    },
    async getMasterPaymentList () {
      let result = null
      result = await RestApi.postData(irriSchemeServiceBaseUrl, getMasterPaymentList + '/' + this.$props.org_id + '/' + this.$props.id, this.circleAreaList)
      if (result.success) {
        this.circleData = result.data.datas
        if (result.data.participationFee) {
          this.participation = result.data.participationFee
        }
      }
    },
    getParticipationAmount (index) {
      const cusecAmount = this.partiCusecList.find(cusec => cusec.value === this.fee.participationFee[index].discharge_cusec)
      this.fee.participationFee[index].amount = cusecAmount.amount
    },
    async register () {
      this.loading = true
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadinState = { loading: false, listReload: false }
      result = await RestApi.postData(irriSchemeServiceBaseUrl, participationFeeStore, this.participation)
      loadinState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadinState)
      this.loading = false
      if (result.success) {
        this.$toast.success({
          title: 'Success',
          message: this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })
        this.$bvModal.hide('modal-1')
      }
    }
  }
}
</script>
