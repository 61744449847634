<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-overlay :show="loading">
              <b-row>
                <b-col v-if="isDataFound" lg="12" sm="12">
                  <p style="font-weight:bold; color:gray; text-transform:uppercase">{{ $t('pump_install.participation_fee') }}</p>
                  <hr style="margin-top:-15px">
                  <b-table-simple striped bordered small>
                      <b-tr>
                          <b-th>{{ $t('pump_install.participation_category') }}</b-th>
                          <b-th>{{ $t('pump_install.discharge_cusec') }}</b-th>
                          <b-th>{{ $t('pump_install.amount') }}</b-th>
                          <b-th>{{ $t('globalTrans.status') }}</b-th>
                          <b-th>{{ $t('pump_install.payment_date') }}</b-th>
                          <b-th>{{ $t('globalTrans.type') }}</b-th>
                      </b-tr>
                      <b-tr v-for="(participation, index) in participationFee" :key="index">
                          <b-td>{{ getParticipationCategory(participation.participation_category_id) }}</b-td>
                          <b-td>{{ participation.discharge_cusec }}</b-td>
                          <b-td>{{ $n(participation.amount) }}</b-td>
                          <b-td>{{ getStatus(participation.payment_status) }}</b-td>
                          <b-td>{{ participation.payment_date ? (participation.payment_date | dateFormat) : '' }}</b-td>
                          <b-td>
                            <span class="badge badge-primary" v-if="participation.payment_type === 1">{{ $t('pump_install.Advanced') }}</span>
                            <span class="badge badge-danger" v-if="participation.payment_type === 2">{{ $t('pump_install.Due') }}</span>
                          </b-td>
                      </b-tr>
                  </b-table-simple>
                  <p style="font-weight:bold; color:gray; text-transform:uppercase">{{ $t('pump_install.security_money') }}</p>
                  <hr style="margin-top:-15px">
                  <b-table-simple striped bordered small>
                      <b-tr>
                          <b-th>{{ $t('pump_install.pump_type') }}</b-th>
                          <b-th>{{ $t('pump_install.discharge_cusec') }}</b-th>
                          <b-th>{{ $t('pump_install.amount') }}</b-th>
                          <b-th>{{ $t('globalTrans.status') }}</b-th>
                          <b-th>{{ $t('pump_install.payment_date') }}</b-th>
                          <b-th>{{ $t('globalTrans.type') }}</b-th>
                      </b-tr>
                      <b-tr v-for="(security, index) in securityMoney" :key="index">
                          <b-td>{{ getPumpType(security.pump_type_id) }}</b-td>
                          <b-td>{{ $n(security.discharge_cusec) }}</b-td>
                          <b-td>{{ $n(security.amount) }}</b-td>
                          <b-td>{{ getStatus(security.payment_status) }}</b-td>
                          <b-td>{{ security.payment_date | dateFormat }}</b-td>
                          <b-td>
                            <span class="badge badge-primary" v-if="security.payment_type === 1">{{$t('pump_install.Advanced') }}</span>
                            <span class="badge badge-danger" v-if="security.payment_type === 2">{{$t('pump_install.Due') }}</span>
                          </b-td>
                      </b-tr>
                  </b-table-simple>
                  <div class="row">
                      <div class="col-sm-3"></div>
                      <div class="col text-right">
                          <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-1')">{{ $t('globalTrans.cancel') }}</b-button>
                      </div>
                  </div>
                </b-col>
                <b-col v-else lg="12" sm="12">
                  <p style="font-weight:bold; color:gray; text-align:center text-transform:uppercase">{{ $t('pump_install.data_not_found') }}</p>
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '../../../../../config/pluginInit'
import RestApi, { irriSchemeServiceBaseUrl } from '../../../../../config/api_config'
import { getParticipationFee } from '../../api/routes'

export default {
  props: ['id', 'org_id'],
  mounted () {
    core.index()
  },
  created () {
    this.loadData()
  },
  data () {
    return {
      isDataFound: true,
      participationCategoryList: [
        { value: 1, text: 'Deep Tubewell' },
        { value: 2, text: 'Irrigation Drain' },
        { value: 3, text: 'Electrification' },
        { value: 4, text: 'Solar' }
      ],
      participationFee: '',
      securityMoney: ''
    }
  },
  computed: {
    pumpTypeList: function () {
      return this.$store.state.IrriConfig.commonObj.pumpType.filter(item => item.status === 0)
    }
  },
  methods: {
    async loadData () {
      this.loading = true
      let result = null
      result = await RestApi.getData(irriSchemeServiceBaseUrl, getParticipationFee + '/' + this.$props.id + '/' + this.$props.org_id)
      this.loading = false
      if (result.success) {
        this.isDataFound = true
        this.participationFee = result.data.participationFee
        this.securityMoney = result.data.securityFee
      } else {
        this.isDataFound = false
      }
    },
    getParticipationCategory (categoryId) {
      if (categoryId === 1) {
        return 'Deep Tubewell'
      } else if (categoryId === 2) {
        return 'Irrigation Drain'
      } else if (categoryId === 3) {
        return 'Electrification'
      } else {
        return 'Solar'
      }
    },
    getStatus (paymentStatus) {
      if (paymentStatus === 1) {
        return this.$t('pump_install.unpaid')
      } else {
        return this.$t('pump_install.paid')
      }
    },
    getPumpType (pumpTypeId) {
      const pumpTypeList = this.$store.state.IrriConfig.commonObj.pumpType.filter(item => item.status === 0)
      const tmpPump = pumpTypeList.find(pumpType => pumpType.value === pumpTypeId)
      return this.$i18n.locale === 'bn' ? tmpPump.text_bn : tmpPump.text_en
    }
  }
}
</script>
