<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
            <template v-slot:body>
                <b-overlay :show="loading">
                    <b-row>
                        <b-col lg="12" sm="12">
                            <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                                <b-form  @submit.prevent="handleSubmit(register)" @reset.prevent="reset" >
                                    <p style="font-weight:bold; color:gray; text-transform:uppercase">{{ $t('pump_install.participation_fee') }}</p>
                                    <hr style="margin-top:-15px">
                                    <b-row>
                                        <b-col lg="3">
                                            <p class="text-dark">{{ $t('pump_install.participation_category') }} <span class="text-danger">*</span></p>
                                        </b-col>
                                        <b-col lg="3">
                                            <p class="text-dark">{{ $t('pump_install.discharge_cusec') }} <span class="text-danger">*</span></p>
                                        </b-col>
                                        <b-col lg="2">
                                            <p class="text-dark">{{ $t('pump_install.amount') }} <span class="text-danger">*</span></p>
                                        </b-col>
                                        <b-col lg="2">
                                            <p class="text-dark">{{ $t('globalTrans.type') }} <span class="text-danger">*</span></p>
                                        </b-col>
                                        <b-col lg="2">
                                            <p class="text-dark">{{ $t('globalTrans.action') }}</p>
                                        </b-col>
                                    </b-row>
                                    <b-row v-for="(participation, index1) in fee.participationFee" :key="`A-${index1}`">
                                        <b-col lg="3">
                                            <ValidationProvider name="Participation Category" vid="participation_category_id" rules="required">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="12"
                                                    label-for="participation_category_id"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                    <b-form-select
                                                        plain
                                                        id="participation_category_id"
                                                        v-model="participation.participation_category_id"
                                                        :options="participationCategoryList"
                                                        @change="getParticipationCusecList(index1)"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                        :disabled="participation.payment_status !==1"
                                                    >
                                                        <template v-slot:first>
                                                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                        </template>
                                                    </b-form-select>
                                                    <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col lg="3">
                                            <ValidationProvider name="Discharge Cusec" vid="discharge_cusec" rules="required|numeric">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="12"
                                                    label-for="discharge_cusec"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                <b-form-select
                                                    id="discharge_cusec"
                                                    v-model="participation.discharge_cusec"
                                                    :options="participation.participationCusecList"
                                                    @change="getParticipationAmount(participation,participation.participationCusecList)"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    :disabled="participation.payment_status !==1"
                                                >
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="0">
                                                            {{ partiCusecLoad ? 'Loading...' : $t('globalTrans.select') }}
                                                        </b-form-select-option>
                                                    </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col lg="2">
                                            <ValidationProvider name="Amount" vid="amount" rules="required|numeric">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="12"
                                                    label-for="amount"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                <b-form-input
                                                    id="amount"
                                                    v-model="participation.amount"
                                                    :readonly="participation.payment_status !==1"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                </b-form-input>
                                                <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col lg="2">
                                            <ValidationProvider name="participation_payment_type" vid="participation_payment_type" rules="required|numeric">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="12"
                                                    label-for="participation_payment_type"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                <b-form-select
                                                    id="participation_payment_type"
                                                    v-model="participation.payment_type"
                                                    :options="participationTypes"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    :disabled="participation.payment_status !==1"
                                                >
                                                    <template v-slot:first>
                                                        <b-form-select-option :value='null'>{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                </b-form-select>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col lg="2" sm="4" >
                                            <b-form-group class="mt-3">
                                                <b-button class="btn btn-outline-danger" v-if="index1 !== 0  && participation.payment_status !==2" variant=" iq-bg-danger" size="sm" @click.prevent="removeParticipationItem(index1)"><i class="ri-delete-bin-line m-0"></i></b-button>
                                                <b-button class="btn btn-outline-success"  v-if="index1 === 0" variant=" iq-bg-success" size="sm" @click="addMoreParticipation"><i class="ri-add-line m-0"></i></b-button>
                                            </b-form-group>
                                        </b-col>
                                    </b-row>
                                    <p style="font-weight:bold; color:gray; text-transform:uppercase">{{ $t('pump_install.security_money') }}</p>
                                    <hr style="margin-top:-15px">
                                    <b-row>
                                    <b-col lg="3">
                                        <p class="text-dark">{{ $t('pump_install.pump_type') }}</p>
                                    </b-col>
                                    <b-col lg="3">
                                        <p class="text-dark">{{ $t('pump_install.discharge_cusec') }} </p>
                                    </b-col>
                                    <b-col lg="2">
                                        <p class="text-dark">{{ $t('pump_install.amount') }} </p>
                                    </b-col>
                                    <b-col lg="2">
                                        <p class="text-dark">{{ $t('globalTrans.type') }} </p>
                                    </b-col>
                                    <b-col lg="2">
                                        <p class="text-dark">{{ $t('globalTrans.action') }}</p>
                                    </b-col>
                                    </b-row>
                                    <b-row v-for="(security, index2) in fee.securityMoney" :key="index2">
                                        <b-col lg="3">
                                            <ValidationProvider name="pump_type_id" vid="pump_type_id">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="12"
                                                    label-for="pump_type_id"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                    <b-form-select
                                                        plain
                                                        id="pump_type_id"
                                                        v-model="security.pump_type_id"
                                                        :options="pumpTypeList"
                                                        @change="getSecurityMoneyCusecList(index2)"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                        :disabled="security.payment_status !==1"
                                                    >
                                                        <template v-slot:first>
                                                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                        </template>
                                                    </b-form-select>
                                                    <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col lg="3">
                                            <ValidationProvider name="discharge_cusec" vid="discharge_cusec">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="12"
                                                    label-for="discharge_cusec"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                    <b-form-select
                                                        plain
                                                        id="discharge_cusec"
                                                        v-model="security.discharge_cusec"
                                                        :options="security.securityMoneyCusecList"
                                                        @change="getSecurityMoneyAmount(security, security.securityMoneyCusecList)"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                        :disabled="security.payment_status !==1"
                                                    >
                                                        <template v-slot:first>
                                                            <b-form-select-option :value="0">
                                                                {{ securityCusecLoad ? 'Loading...' : $t('globalTrans.select') }}
                                                            </b-form-select-option>
                                                        </template>
                                                    </b-form-select>
                                                <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col lg="2">
                                            <ValidationProvider name="amount" vid="amount">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="12"
                                                    label-for="amount"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                <b-form-input
                                                    id="amount"
                                                    v-model="security.amount"
                                                    :readonly="security.payment_status !==1"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                </b-form-input>
                                                <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col lg="2">
                                            <ValidationProvider name="security_payment_type" vid="security_payment_type">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="12"
                                                    label-for="security_payment_type"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                <b-form-select
                                                    id="security_payment_type"
                                                    v-model="security.payment_type"
                                                    :options="participationTypes"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    :disabled="security.payment_status !==1"
                                                >
                                                    <template v-slot:first>
                                                        <b-form-select-option :value='null'>{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                </b-form-select>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col lg="2" sm="4">
                                            <b-form-group class="mt-3">
                                                <b-button class="btn btn-outline-danger" v-if="index2 !== 0  && security.payment_status !==2" variant=" iq-bg-danger" size="sm" @click.prevent="removeSecurityItem(index2)"><i class="ri-delete-bin-line m-0"></i></b-button>
                                                <b-button class="btn btn-outline-success" v-if="index2 === 0" variant=" iq-bg-success" size="sm" @click="addMoreSecurity"><i class="ri-add-line m-0"></i></b-button>
                                            </b-form-group>
                                        </b-col>
                                    </b-row>
                                    <div class="row">
                                        <div class="col-sm-3"></div>
                                        <div class="col text-right">
                                            <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                                            &nbsp;
                                            <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-1')">{{ $t('globalTrans.cancel') }}</b-button>
                                        </div>
                                    </div>
                                </b-form>
                            </ValidationObserver>
                        </b-col>
                    </b-row>
                </b-overlay>
            </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '../../../../../config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { irriSchemeServiceBaseUrl } from '../../../../../config/api_config'
import { participationFeeStore, getParticipationCusec, getSecurityMoneyCusec, getParticipationFee } from '../../api/routes'

export default {
  props: ['id', 'org_id', 'application_type_id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    this.checkFee(this.$props.id)
  },
  mounted () {
    core.index()
  },
  data () {
    return {
        saveBtnName: this.$t('globalTrans.save'),
        loading: false,
        isEdit: false,
        partiCusecLoad: false,
        securityCusecLoad: false,
        participationCategoryList: [
            { value: 1, text: 'Deep Tubewell' },
            { value: 2, text: 'Irrigation Drain' },
            { value: 3, text: 'Electrification' },
            { value: 4, text: 'Solar' }
        ],
        fee: {
            scheme_application_id: this.$props.id,
            org_id: this.$props.org_id,
            participationFee: [
                {
                    participation_category_id: 0,
                    discharge_cusec: 0,
                    payment_status: 1,
                    amount: '',
                    payment_type: null,
                    participationCusecList: []
                }
            ],
            securityMoney: [
                {
                    pump_type_id: 0,
                    discharge_cusec: 0,
                    payment_status: 1,
                    amount: '',
                    payment_type: null,
                    securityMoneyCusecList: []
                }
            ]
        },
        partiCusecList: [],
        secCusecList: []
    }
  },
  computed: {
    participationTypes: function () {
        const participationType = [
            { value: 1, text: this.$t('pump_install.Advanced') },
            { value: 2, text: this.$t('pump_install.Due') }
        ]
        return participationType
    },
    pumpTypeList: function () {
        return this.$store.state.IrriConfig.commonObj.pumpType.filter(item => item.status === 0)
    }
  },
  methods: {
    getParticipationCusecList (index) {
        this.partiCusecLoad = true
        const participationCategoryId = this.fee.participationFee[index].participation_category_id
        const params = { application_type_id: this.$props.application_type_id, participation_category_id: participationCategoryId, org_id: this.fee.org_id }
        RestApi.getData(irriSchemeServiceBaseUrl, getParticipationCusec, params).then(response => {
            this.partiCusecList = response.data
            this.fee.participationFee[index].participationCusecList = response.data
            const tmp = this.fee.participationFee
            this.fee.participationFee = []
            this.fee.participationFee = tmp
            this.partiCusecLoad = false
        })
    },
    getParticipationAmount (participation, partiCusecList) {
        const cusecAmount = partiCusecList.find(cusec => cusec.value === participation.discharge_cusec)
        participation.amount = cusecAmount.amount
    },
    getSecurityMoneyCusecList (index) {
        this.securityCusecLoad = true
        const pumpTypeId = this.fee.securityMoney[index].pump_type_id
        const params = { application_type_id: this.$props.application_type_id, pump_type_id: pumpTypeId, org_id: this.fee.org_id }
        RestApi.getData(irriSchemeServiceBaseUrl, getSecurityMoneyCusec, params).then(response => {
            this.secCusecList = response.data
            this.fee.securityMoney[index].securityMoneyCusecList = response.data
            const tmp = this.fee.securityMoney
            this.fee.securityMoney = []
            this.fee.securityMoney = tmp
            this.securityCusecLoad = false
        })
    },
    getSecurityMoneyAmount (security, secCusecList) {
        const cusecAmount = secCusecList.find(cusec => cusec.value === security.discharge_cusec)
        security.amount = cusecAmount.amount
    },
    async checkFee (applicationId) {
        this.loading = true
        let result = null
        result = await RestApi.getData(irriSchemeServiceBaseUrl, getParticipationFee + '/' + applicationId + '/' + this.$props.org_id)
        if (result.success) {
            this.isEdit = true
            this.fee.participationFee = result.data.participationFee
            this.fee.securityMoney = result.data.securityFee
        } else {
            this.isEdit = false
        }
        this.loading = false
    },
    async register () {
        this.loading = true
        this.$store.dispatch('mutateCommonProperties', { loading: true })
        let result = null
        const loadinState = { loading: false, listReload: false }
        result = await RestApi.postData(irriSchemeServiceBaseUrl, participationFeeStore, this.fee)
        loadinState.listReload = true
        this.$store.dispatch('mutateCommonProperties', loadinState)
        this.loading = false
        if (result.success) {
            this.$toast.success({
            title: 'Success',
            message: this.$t('globalTrans.save_msg'),
            color: '#D6E09B'
            })
            this.$bvModal.hide('modal-1')
        }
    },
    removeParticipationItem (index) {
      this.fee.participationFee.splice(index, 1)
    },
    addMoreParticipation () {
      const tempItem = {
        participation_category_id: 0,
        discharge_cusec: 0,
        payment_status: 1,
        payment_type: null,
        amount: ''
      }
      this.fee.participationFee.push(tempItem)
      // const key1 = parseInt(this.requisition.items.length - 1)
      // const item = this.requisition.items[key1]
      // let isEmpty = true
      // Object.keys(item).map(key => {
      //   if (key !== 'itemList') {
      //     if (item[key] === '' || item[key] === 0) {
      //       isEmpty = false
      //     }
      //   }
      // })
      // if (isEmpty === true) {
      //   this.requisition.items.push(tempItem)
      // }
    },
    removeSecurityItem (index) {
      this.fee.securityMoney.splice(index, 1)
    },
    addMoreSecurity () {
      const tempItem = {
        pump_type_id: 0,
        discharge_cusec: 0,
        payment_status: 1,
        payment_type: null,
        amount: ''
      }
      this.fee.securityMoney.push(tempItem)
      // const key1 = parseInt(this.requisition.items.length - 1)
      // const item = this.requisition.items[key1]
      // let isEmpty = true
      // Object.keys(item).map(key => {
      //   if (key !== 'itemList') {
      //     if (item[key] === '' || item[key] === 0) {
      //       isEmpty = false
      //     }
      //   }
      // })
      // if (isEmpty === true) {
      //   this.requisition.items.push(tempItem)
      // }
    }
  }
}
</script>
